export const aboutText = `Cloud Protegida é uma empresa especializada em segurança da informação e cibersegurança,
fundada em 2022 vem se aprimorando cada vez mais em técnicas e metodologias de segurança com profissionais
certificados para garantir uma entrega de excelência em seus projetos.`;

export const aboutText2 = ` Trabalhamos para conquistar sua confiança em nossa transparência e a privacidade dos
dados de seus clientes. Com uma equipe altamente qualificada e experiente, oferecemos uma ampla gama de serviços
de segurança, incluindo soluções de segurança de rede, gerenciamento de vulnerabilidades e muito mais.`;

export const missionText = `Nossa missão é garantir a segurança de dados de nossos clientes, através da integridade
e confidencialidade de suas informações, com o objetivo de proteger seus negócios e garantir a continuidade de suas operações.
Buscando sempre promover soluções inovadoras e confiáveis, com o objetivo de entregas de excelência baseada em nossos valores.`;
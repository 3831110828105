import { useContext, useEffect } from 'react';
import AppContext from '../context/AppContext';
import useOnScreen from '../hook/useOnScreen';
import '../styles/Pages.css';
import '../styles/Mission.css';
import { missionText } from '../data/Texts';
import ethic from '../images/ethic.svg';
import commitment from '../images/commitment.svg';
import respect from '../images/respect.svg';
import integrity from '../images/integrity.svg';
import agility from '../images/agility.svg';
import excellence from '../images/excellence.svg';

function Mission() {
  const { missionRef, titleMissionRef, setSelected } = useContext(AppContext);
  const isVisible = useOnScreen(titleMissionRef);

  useEffect(() => {
    if (isVisible) {
      setSelected("missão");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);
  
  return (
    <section className="mission-container" ref={missionRef}>
      <div className="mission">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className="shape">
          <path fill="#0071cc" fillOpacity="1" d="M0,64L48,96C96,128,192,192,288,229.3C384,267,480,277,576,256C672,235,768,181,864,138.7C960,96,1056,64,1152,48C1248,32,1344,32,1392,32L1440,32L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
        </svg>
        <div className="title">
          <h1 ref={titleMissionRef}>Missão</h1>
        </div>
      </div>
      <div className="mission-content">
        <div className="content-first">
          <h3 className="values-title">Nossos valores</h3>
          <div className="values">
            <div className="icon">
              <div className="wrap">
                <img src={ ethic } alt="ethic icon" />
              </div>
              <span className="values-names">Ética</span>
            </div>
            <div className="icon">
              <div className="wrap">
                <img src={ commitment } alt="commitment icon" />
              </div>
              <span className="values-names">Comprometimento</span>
            </div>
            <div className="icon">
              <div className="wrap">
                <img src={ respect } alt="respect icon" />
              </div>
              <span className="values-names">Respeito</span>
            </div>
            <div className="icon">
              <div className="wrap">
                <img src={ integrity } alt="integrity icon" />
              </div>
              <span className="values-names">Integridade</span>
            </div>
            <div className="icon">
              <div className="wrap">
                <img src={ agility } alt="agility icon" />
              </div>
              <span className="values-names">Agilidade</span>
            </div>
            <div className="icon">
              <div className="wrap">
                <img src={ excellence } alt="excellence icon" />
              </div>
              <span className="values-names">Excelência</span>
            </div>
          </div>
        </div>
        <div className="content-second">
          <p className="mission-text">
            { missionText }
          </p>
        </div>
      </div>
    </section>
  );
}

export default Mission;

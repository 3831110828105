import { useContext, useEffect } from 'react';
import AppContext from '../context/AppContext';
import Cloud from '../images/cloud.png';
import Pc from '../images/pc.png';
import Cubes from '../images/cubes.png';
import Shadow from '../images/cube-shadow.png';
import '../styles/Pages.css';
import { AiOutlineArrowRight } from 'react-icons/ai'
import useOnScreen from '../hook/useOnScreen';

function Home() {
  const { homeRef, aboutRef, titleHomeRef, executeScroll, setSelected } = useContext(AppContext);

  const isVisible = useOnScreen(titleHomeRef);

  useEffect(() => {
    if (isVisible) {
      setSelected("home");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
      <section className="page-container home" ref={homeRef}>
        <div className="home-title">
          <h1 ref={titleHomeRef}>Todas as suas necessidades de cloud em um único lugar</h1>
          <div
            className="home-btn"
            onClick={(e) => executeScroll(aboutRef, "about")}
          >
            <span>Conheça mais sobre nós</span>
            <AiOutlineArrowRight className="btn-icon" />
          </div>
        </div>
        <div className="cloud-pc">
          <img src={Cloud} alt="Cloud" className="cloud" />
          <img src={Pc} alt="PC" className="pc" />
          <img src={Cubes} alt="Cubes" className="cubes" />
          <div className="shadows">
            <img src={Shadow} alt="Cubes shadows" id="shadow-1" />
            <img src={Shadow} alt="Cubes shadows" id="shadow-2" />
            <img src={Shadow} alt="Cubes shadows" id="shadow-3" />
          </div>
        </div>
      </section>
  );
}

export default Home;

import { useContext, useEffect, useState } from 'react';
import AppContext from '../context/AppContext';
import Logo from '../images/protect-cloud-logo.png';
import '../styles/Header.css';

function Header() {
  const { homeRef, aboutRef, missionRef, contactRef, selected, executeScroll } = useContext(AppContext);
  const [isMobile, setIsMobile] = useState(false);
  // const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const resizeListener = () => {
      const screenWidth = window.screen.width;

      if (screenWidth <= 790) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    resizeListener();
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  }, [])

  return (
    <header className={ `${isMobile ? 'header mobile' : 'header' }` }>
      <div className="box-logo">
        <img src={Logo} alt="Protect Cloud" className="logo" />
        <span>Cloud Protegida</span>
      </div>
      <nav className="nav">       
        <ul className="nav-list">
          <li
            className={`nav-item ${selected === 'home' ? 'underlined' : ''}`}
            onClick={(e) => executeScroll(homeRef, e)}
          >
            Home
          </li>
          <li
            className={`nav-item ${selected === 'sobre nós' ? 'underlined' : ''}`}
            onClick={(e) => executeScroll(aboutRef, e)}
          >
            Sobre nós
          </li>
          <li
            className={`nav-item ${selected === 'missão' ? 'underlined' : ''}`}
            onClick={(e) => executeScroll(missionRef, e)}
          >
            Missão
          </li>
          <li
            className={`nav-item ${selected === 'contato' ? 'underlined' : ''}`}
            onClick={(e) => executeScroll(contactRef, e)}
          >
            Contato
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default Header;
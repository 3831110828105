import './styles/App.css';
import Header from './components/Header';
import Home from './pages/Home';
import About from './pages/About';
import Mission from './pages/Mission';
import Contact from './pages/Contact';

function App() {
  return (
    <div className="container">
      <Header />
      <Home />
      <About />
      <Mission />
      <Contact />
      <footer className="footer">
        <span>Cloud Protegida © 2023 - Todos os direitos reservados</span>
        <span>Desenvolvido por: &nbsp;
          <a href="https://giuseppeusn.github.io/" target="_blank" rel="noreferrer">
            <span className="name-link">Giuseppe Nunes</span>
          </a>
        </span>
      </footer>
    </div>
  );
}

export default App;

import { useContext, useEffect } from 'react';
import AppContext from '../context/AppContext';
import '../styles/Pages.css';
import '../styles/About.css';
import useOnScreen from '../hook/useOnScreen';
import { aboutText, aboutText2 } from '../data/Texts';
import aws from '../images/aws.svg';
import linux from '../images/linux.svg';
import network from '../images/network.svg';
import cloud from '../images/cloud.svg';
import books from '../images/books.svg';
import devops from '../images/devops.svg';

function About() {
  const { aboutRef, titleAboutRef, setSelected } = useContext(AppContext);
  const isVisible = useOnScreen(titleAboutRef);

  useEffect(() => {
    if (isVisible) {
      setSelected("sobre nós");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
      <section className="about-container" ref={aboutRef}>
        <div className="about">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className="shape">
            <path fill="#009ffd" fillOpacity="1" d="M0,0L48,10.7C96,21,192,43,288,58.7C384,75,480,85,576,122.7C672,160,768,224,864,240C960,256,1056,224,1152,181.3C1248,139,1344,85,1392,58.7L1440,32L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
          </svg>
          <div className="title">
            <h1 ref={titleAboutRef}>Sobre nós</h1>
          </div>
        </div>
        <div className="about-content">
          <div className="content-first">
            <p className="about-text">
              { aboutText }
            </p>
            <p className="about-text">
              { aboutText2 }
            </p>
          </div>
          <div className="content-second">
            <h3 className="services-title">Soluções em...</h3>
            <div className="services">
              <div className="icon">
                <div className="wrap">
                  <img src={ network } alt="network icon" />
                </div>
                <span className="services-names">Redes</span>
              </div>
              <div className="icon">
                <div className="wrap">
                  <img src={ linux } alt="linux icon" />
                </div>
                <span className="services-names">Linux</span>
              </div>
              <div className="icon">
                <div className="wrap">
                  <img src={ cloud } alt="cloud icon" />
                </div>
                <span className="services-names">Cloud</span>
              </div>
              <div className="icon">
                <div className="wrap">
                  <img src={ aws } alt="aws icon" />
                </div>
                <span className="services-names">Amazon<br></br> Web Service</span>
              </div>
              <div className="icon">
                <div className="wrap">
                  <img src={ devops } alt="devops icon" />
                </div>
                <span className="services-names">DevOps<br></br> & SRE</span>
              </div>
              <div className="icon">
                <div className="wrap">
                  <img src={ books } alt="books icon" />
                </div>
                <span className="services-names">Cursos<br></br> e treinamentos</span>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
}

export default About;

import React, { useRef, useState } from 'react';
import AppContext from './AppContext';

function AppProvider({ children }) {
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const missionRef = useRef(null);
  const contactRef = useRef(null);
  const titleHomeRef = useRef(null);
  const titleAboutRef = useRef(null);
  const titleMissionRef = useRef(null);
  const titleContactRef = useRef(null);


  const [selected, setSelected] = useState('home');

  const executeScroll = (ref, elem) => {
    let menuSelected = elem;

    if (elem.target) {
      const { target: { innerText } } = elem;

      menuSelected = innerText.toLowerCase();
    }

    setSelected(menuSelected);
    ref.current.scrollIntoView()
  };

  const contextValue = {
    homeRef,
    aboutRef,
    missionRef,
    contactRef,
    selected,
    setSelected,
    executeScroll,
    titleHomeRef,
    titleAboutRef,
    titleMissionRef,
    titleContactRef,
  };

  return (
    <AppContext.Provider value={ contextValue }>
      {children}
    </AppContext.Provider>
  );
}

export default AppProvider;
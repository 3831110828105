import { useContext, useEffect, useRef, useState } from 'react';
import AppContext from '../context/AppContext';
import useOnScreen from '../hook/useOnScreen';
import emailjs from '@emailjs/browser';
import '../styles/Pages.css';
import '../styles/Contact.css';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

const {
  EMAIL_SERVICE_ID = 'service_kr3mbse',
  EMAIL_TEMPLATE_ID = 'template_c0xhizq',
  EMAIL_PUBLIC_KEY = '8wSyFQKqL5INAegsB' 
} = process.env;

function Contact() {
  const { contactRef, titleContactRef, setSelected } = useContext(AppContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState({
    error: "",
    message: "",
  })
  const [loading, setLoading] = useState(false);

  const form = useRef();

  const isVisible = useOnScreen(titleContactRef);

  useEffect(() => {
    if (isVisible) {
      setSelected("contato");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const handleName = (e) => {
    setName(e.target.value);

    setErrorMessage({
      error: "",
      message: "",
    });
  }

  const handleEmail = (e) => {
    setEmail(e.target.value);

    setErrorMessage({
      error: "",
      message: "",
    });
  }

  const handleMessage = (e) => {
    setMessage(e.target.value);

    setErrorMessage({
      error: "",
      message: "",
    });
  }

  const sendEmail = (e) => {
    e.preventDefault();

    if (validateEmail()) {
      setLoading(true);

      emailjs.sendForm(EMAIL_SERVICE_ID, EMAIL_TEMPLATE_ID, form.current, EMAIL_PUBLIC_KEY)
        .then(() => {
          setLoading(false);
          setErrorMessage({
            error: "sucess",
            message: 'Email enviado com sucesso',
          });

          setName("");
          setEmail("");
          setMessage("");
        }, () => {
          setLoading(false);

          setErrorMessage({
            error: "error",
            message: 'Erro ao enviar email. Tente novamente!',
          });
        });

        setTimeout(() => {
          setErrorMessage({
            error: "",
            message: "",
          });
        }, 5000);
      
    }
  }

  const validateEmail = () => {
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    
    switch (true) {
      case !name:
        setErrorMessage({
          error: "name",
          message: 'Campo nome não preenchido',
        });
        return false;
      case !email:
        setErrorMessage({
          error: "email",
          message: 'Campo email não preenchido',
        });
        return false;
      case !emailRegex.test(email):
        setErrorMessage({
          error: "email",
          message: 'Campo email inválido',
        });
        return false;
      case !message:
        setErrorMessage({
          error: "message",
          message: 'Campo messagem não preenchido',
        });
        break;
      default:
        return true;
    }
  }

  return (
      <section className="contact-container">
        <div className="contact" ref={contactRef}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className="shape">
            <path fill="#009ffd" fillOpacity="1" d="M0,0L48,10.7C96,21,192,43,288,58.7C384,75,480,85,576,122.7C672,160,768,224,864,240C960,256,1056,224,1152,181.3C1248,139,1344,85,1392,58.7L1440,32L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
          </svg>
          <div className="title">
            <h1 ref={titleContactRef}>Contato</h1>
          </div>
        </div>
        <form className="contact-form" ref={form}>
          <p className="form-text">
            Caso tenha interesse ou dúvida nos serviços da Protect Cloud
            entre em contato preenchendo o formulário abaixo
          </p>
          <div className="upper-line">
            <input
              type="text"
              placeholder="Nome"
              value={name}
              onChange={(e) => handleName(e)}
              className={ errorMessage.error === "name" ? "error" : "input-default"}
            />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => handleEmail(e)}
              className={ errorMessage.error === "email" ? "error" : "input-default"}
            />
          </div>
          <textarea
            placeholder="Sua mensagem"
            value={message}
            onChange={(e) => handleMessage(e)}
            className={ errorMessage.error === "message" ? "error" : "input-default"}
          />
          <span className={ errorMessage.error ? "error-text" : "none-error" }>
            {errorMessage.message}
          </span>
          <button type="submit" onClick={ sendEmail } disabled={loading}>
            { loading ?
                (
                  <AiOutlineLoading3Quarters className="loading" />
                ) : (
                  <span>Enviar</span>
                )
            }
          </button>
        </form>
      </section>
  );
}

export default Contact;
